const _temp0 = require("url:./branqueamento.jpeg");
const _temp1 = require("url:./crianca1.jpeg");
const _temp2 = require("url:./crianca2.jpeg");
const _temp3 = require("url:./dentes.jpeg");
const _temp4 = require("url:./diana1.jpeg");
const _temp5 = require("url:./estetica.jpeg");
const _temp6 = require("url:./operacao.jpeg");
const _temp7 = require("url:./paciente1.jpeg");
const _temp8 = require("url:./selfie2.jpeg");
module.exports = {
  "branqueamento.jpeg": _temp0,
  "crianca1.jpeg": _temp1,
  "crianca2.jpeg": _temp2,
  "dentes.jpeg": _temp3,
  "diana1.jpeg": _temp4,
  "estetica.jpeg": _temp5,
  "operacao.jpeg": _temp6,
  "paciente1.jpeg": _temp7,
  "selfie2.jpeg": _temp8
}