const _temp0 = require("url:./ADMG.png");
const _temp1 = require("url:./Agilcare.png");
const _temp2 = require("url:./Associacao Sindical de Professores Licensiados 2.png");
const _temp3 = require("url:./Associacao Sindical de Professores Licensiados.png");
const _temp4 = require("url:./BS Care.png");
const _temp5 = require("url:./Bombeiros Voluntarios Cruz Branca.png");
const _temp6 = require("url:./Bombeiros Voluntarios Cruz Verde.png");
const _temp7 = require("url:./Farmacia Baptista.png");
const _temp8 = require("url:./GimnosCult Ginasio.png");
const _temp9 = require("url:./Laboratorio Pioledo Unilabs.png");
const _temp10 = require("url:./MeltiMalon.png");
const _temp11 = require("url:./SAMS Quadros.png");
const _temp12 = require("url:./SS GNR.png");
const _temp13 = require("url:./Servico Medico Permanente.png");
const _temp14 = require("url:./UBHPlan.png");
const _temp15 = require("url:./medicare.png");
const _temp16 = require("url:./placeholder.png");
const _temp17 = require("url:./sorriso.png");
module.exports = {
  "ADMG.png": _temp0,
  "Agilcare.png": _temp1,
  "Associacao Sindical de Professores Licensiados 2.png": _temp2,
  "Associacao Sindical de Professores Licensiados.png": _temp3,
  "BS Care.png": _temp4,
  "Bombeiros Voluntarios Cruz Branca.png": _temp5,
  "Bombeiros Voluntarios Cruz Verde.png": _temp6,
  "Farmacia Baptista.png": _temp7,
  "GimnosCult Ginasio.png": _temp8,
  "Laboratorio Pioledo Unilabs.png": _temp9,
  "MeltiMalon.png": _temp10,
  "SAMS Quadros.png": _temp11,
  "SS GNR.png": _temp12,
  "Servico Medico Permanente.png": _temp13,
  "UBHPlan.png": _temp14,
  "medicare.png": _temp15,
  "placeholder.png": _temp16,
  "sorriso.png": _temp17
}