const _temp0 = require("url:./operacao1.jpeg");
const _temp1 = require("url:./paciente1.jpeg");
const _temp2 = require("url:./selfie2.jpeg");
const _temp3 = require("url:./temp_about.png");
const _temp4 = require("url:./temp_xray.jpg");
module.exports = {
  "operacao1.jpeg": _temp0,
  "paciente1.jpeg": _temp1,
  "selfie2.jpeg": _temp2,
  "temp_about.png": _temp3,
  "temp_xray.jpg": _temp4
}